@font-face {
  font-family: Avenir-Book;
  src: url('./AvenirLTStd-Book.otf');
}

@font-face {
  font-family: Avenir-Medium;
  src: url('./AvenirLTStd-Medium.otf');
}

@font-face {
  font-family: Avenir-Roman;
  src: url('./AvenirLTStd-Roman.otf');
}
