.rbc-calendar {
  font-family: 'Avenir-Book', sans-serif;
  max-height: calc(100vh - 125px);
}

.rbc-today {
  background-color: #fb5f2618;
}

.rbc-allday-cell {
  display: none;
}

.rbc-current-time-indicator {
  background-color: #fb5f26;
  height: 4px;
  z-index: 5;
}

.rbc-time-content {
  flex: 0 1 100%;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.3);
}
.rbc-header {
  border-bottom: none;
  padding: 10px 0;
  font-family: 'Avenir-Book', sans-serif;
  font-size: 1rem;
}

.rbc-time-view {
  border: none;
}

.rbc-header + .rbc-header,
.rbc-day-bg + .rbc-day-bg,
.rbc-time-header-content {
  border-left: none;
}

.rbc-time-slot {
  text-align: center;
  font-family: 'Avenir-Book', sans-serif;
  border: none !important;
}

.rbc-day-slot .rbc-event {
  border-radius: 0;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  border: none;
  transition: 200ms;
  z-index: 3;
}

.rbc-day-slot .rbc-event.rbc-past-event {
  filter: contrast(0.3) brightness(1.5);
  z-index: 0;
}

.rbc-event-content {
  font-family: 'Avenir-Book', sans-serif;
}

.rbc-event-label {
  font-family: 'Avenir-Book', sans-serif;
}

.rbc-month-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
}

.rbc-month-row {
  min-height: 130px;
}

.rbc-addons-dnd-dragged-event {
  opacity: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resizable {
  position: relative;
  width: 100%;
  height: 100%;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor {
  width: 100%;
  text-align: center;
  position: absolute;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:first-child {
  top: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:last-child {
  bottom: 0;
}

.rbc-addons-dnd
  .rbc-addons-dnd-resize-ns-anchor
  .rbc-addons-dnd-resize-ns-icon {
  display: none;
  border-top: 3px double;
  margin: 0 auto;
  width: 10px;
  cursor: ns-resize;
}

.rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ns-icon,
.rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ew-icon {
  display: block;
}
